import { createI18n } from "vue-i18n";
import { Dictionary } from "@/types";
import nl from "./data/language/dictionaries/nl";

const i18n = createI18n<[Dictionary], "nl">({
    locale: "nl",
    messages: {
        nl: nl,
    },
    warnHtmlInMessage: "off",
});

export default i18n;
