import { ConversionRouteNames } from "@/types";

type ConversionLib = {
    [key in ConversionRouteNames]: string;
};

export const conversionLabels: ConversionLib = {
    CheckoutOffline: "Dvv-CIzwtaAZEM6cjr4D",
    CheckoutOnline: "CyZZCPnbm_cYEM6cjr4D",
    CheckoutReExam: "QTmMCP3Ro_cYEM6cjr4D",
    CheckoutUpsell: "mzBzCPyAoPcYEM6cjr4D",
    CheckoutUpgrade: "8mzgCLeBzf8YEM6cjr4D",
    CheckoutExtend: "",
    // this on is probably the one made via google tag manager.
    // should be removed permanently when setup is working
    //PaymentFirstSubscriptionSuccess: "xqT-CKLk6bwDEM6cjr4D",
    PaymentOfflineSuccess: "mmzBCOHguI0ZEM6cjr4D",
    PaymentOnlineSuccess: "Pc8eCOTguI0ZEM6cjr4D",
    PaymentReExamSuccess: "G0mtCOfguI0ZEM6cjr4D",

    // these routes are empty since they don't come from an advertisements
    PaymentUpsellExamSuccess: "",
    PaymentUpsellUpgradeSuccess: "",
    PaymentUpsellExtendSuccess: "",
};
