import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_button = _resolveComponent("etd-button")!
  const _component_etd_container = _resolveComponent("etd-container")!

  return (_openBlock(), _createBlock(_component_etd_container, { gap: "l" }, {
    default: _withCtx(() => [
      _createVNode(_component_etd_button, {
        "icon-id": "whatsapp",
        "icon-style": "brands",
        "icon-color": "whatsapp",
        theme: "white",
        href: 
                'https://api.whatsapp.com/send?phone=' +
                _ctx.etdConsts.contact.telephone.number
            ,
        target: "_blank",
        justify: "start"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.etdConsts.contact.telephone.label), 1)
        ]),
        _: 1
      }, 8, ["href"]),
      _createVNode(_component_etd_button, {
        "icon-id": "phone",
        "icon-style": "duotone",
        "icon-color": "blue-5",
        theme: "white",
        href: 'tel:' + _ctx.etdConsts.contact.telephone.number,
        justify: "start"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.etdConsts.contact.telephone.label), 1)
        ]),
        _: 1
      }, 8, ["href"]),
      _createVNode(_component_etd_button, {
        "icon-id": "envelope",
        "icon-style": "duotone",
        "icon-color": "blue-5",
        theme: "white",
        href: 'mailto:' + _ctx.etdConsts.contact.email.customerService,
        justify: "start"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.etdConsts.contact.email.customerService), 1)
        ]),
        _: 1
      }, 8, ["href"])
    ]),
    _: 1
  }))
}