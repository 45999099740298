import { defineStore } from "pinia";

interface UiState {
    displayHelpdeskPopup: boolean;
    currentSlide: number;
}

export const useUiStore = defineStore("ui", {
    state: () => {
        const state: UiState = {
            displayHelpdeskPopup: false,
            currentSlide: 0,
        };
        return state;
    },
    getters: {},
    actions: {
        nextSlide() {
            this.currentSlide++;
        },
        previousSlide() {
            this.currentSlide--;
        },
        jumpToSlide(s: number) {
            this.currentSlide = s;
        },
    },
});
