
import { defineComponent } from "vue";

export default defineComponent({
    name: "OverviewConditions",
    props: {
        consent: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {
        const toggle = () => emit("set-consent", !props.consent);

        return {
            toggle,
        };
    },
});
