import { defineStore } from "pinia";
import { PersonalInfoState, TestScenarioFormItem } from "@/types";

const getMockedForm = (mockedForm: TestScenarioFormItem[]) => {
    const v: any = {};
    for (const item of mockedForm) {
        v[item.id] = item.value;
    }
    return v;
};

export const usePersonalInfoStore = defineStore("personal info", {
    state: () => {
        const state: PersonalInfoState = {
            subscription_type: "",
            form: {
                firstname: "",
                insertion: "",
                lastname: "",
                dob: "",
                email: "",
                cell: "",
                zipcode: "",
                number: "",
                street: "",
                city: "",
                hash: "",
            },
            selfBooking: {
                location: null,
                date: "",
                time: "",
            },
            consent: false,
            formValid: false,
            reExamID: "",
        };
        return state;
    },
    getters: {},
    actions: {},
});
