import { Route } from "@/types/";
import {
    paymentFailRoutes,
    paymentSuccessRoutes,
} from "@/router/routes/payment";
import { checkoutRoutes } from "@/router/routes/checkout";
import {
    infoRoute,
    paymentCheckRoute,
    plannerRoutes,
} from "@/router/routes/other";

export const routes: Route[] = [
    plannerRoutes,
    paymentCheckRoute,
    infoRoute,
    ...checkoutRoutes,
    ...paymentSuccessRoutes,
    ...paymentFailRoutes,
];
