import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c4820f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "OverviewPersonalInfo" }
const _hoisted_2 = { "data-locator": "slide-head" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { style: {"font-weight":"700"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_icon_button = _resolveComponent("etd-icon-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t("slides.SlideOverview.personalInfo.head")) + " ", 1),
      _createVNode(_component_etd_icon_button, {
        onClick: _ctx.jumpTo,
        "icon-id": "pen-to-square",
        color: "blue-5"
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", {
      innerHTML: _ctx.$t('slides.SlideOverview.personalInfo.body')
    }, null, 8, _hoisted_3),
    _createElementVNode("dl", _hoisted_4, [
      _createElementVNode("dt", null, _toDisplayString(_ctx.$t("slides.SlideOverview.personalInfo.form.firstname")), 1),
      _createElementVNode("dd", null, _toDisplayString(_ctx.personalInfo.firstname), 1),
      _createElementVNode("dt", null, _toDisplayString(_ctx.$t("slides.SlideOverview.personalInfo.form.insertion")), 1),
      _createElementVNode("dd", null, _toDisplayString(_ctx.personalInfo.insertion), 1),
      _createElementVNode("dt", null, _toDisplayString(_ctx.$t("slides.SlideOverview.personalInfo.form.lastname")), 1),
      _createElementVNode("dd", null, _toDisplayString(_ctx.personalInfo.lastname), 1),
      _createElementVNode("dt", null, _toDisplayString(_ctx.$t("slides.SlideOverview.personalInfo.form.dob")), 1),
      _createElementVNode("dd", null, _toDisplayString(_ctx.formattedDob), 1),
      _createElementVNode("dt", null, _toDisplayString(_ctx.$t("slides.SlideOverview.personalInfo.form.email")), 1),
      _createElementVNode("dd", null, _toDisplayString(_ctx.personalInfo.email), 1),
      _createElementVNode("dt", null, _toDisplayString(_ctx.$t("slides.SlideOverview.personalInfo.form.telephone")), 1),
      _createElementVNode("dd", null, _toDisplayString(_ctx.personalInfo.cell), 1)
    ])
  ]))
}