import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_checkbox_deluxe = _resolveComponent("etd-checkbox-deluxe")!

  return (_openBlock(), _createBlock(_component_etd_checkbox_deluxe, {
    "is-active": _ctx.isActive,
    label: _ctx.location[_ctx.label],
    value: _ctx.location.id
  }, null, 8, ["is-active", "label", "value"]))
}