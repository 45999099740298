export const measureSlider = (currentSlide: number) => {
    const slideElement = document.getElementById("slide-" + currentSlide);
    if (slideElement) {
        const height = slideElement.clientHeight;
        const containerElement = document.getElementById("slider-container");
        if (containerElement) {
            containerElement.style.height = height + "px";
        }
    }
};
