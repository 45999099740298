
import { defineComponent, inject } from "vue";
import { Option } from "@/types";
import { SLIDE } from "../../../../../data/constants";
import OptionGroup from "../OptionGroup.vue";

export default defineComponent({
    name: SLIDE.EXAM_TYPES,
    components: { OptionGroup },
    props: {
        data: {
            type: Object,
            required: true,
        },
        selection: {
            type: Object,
            required: false,
        },
    },
    setup(props, { emit }) {
        const mq = inject("mq");
        const entry = `slides.${SLIDE.EXAM_TYPES}.`;

        return {
            select: (item: Option) => emit("select", item),
            mq,
            entry,
        };
    },
});
