import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26a7fbd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "element" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "regular", {}, undefined, true)
    ], 512),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createElementVNode("div", {
        class: _normalizeClass([{ 'sticky--active': _ctx.isSticky }, "sticky"])
      }, [
        _renderSlot(_ctx.$slots, "sticky", {}, undefined, true)
      ], 2)
    ]))
  ], 64))
}