import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_overview_summary_item = _resolveComponent("overview-summary-item")!
  const _component_etd_container = _resolveComponent("etd-container")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_etd_container, { gap: "xxl" }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("slides.SlideOverview.summary.head")), 1),
          _createElementVNode("div", {
            innerHTML: _ctx.$t('slides.SlideOverview.summary.body')
          }, null, 8, _hoisted_1)
        ]),
        _createVNode(_component_etd_container, {
          direction: _ctx.mq.t === 's' ? 'column' : 'row',
          gap: "m",
          n: 2,
          wrap: true
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaryItems, (summaryItem, index) => {
              return (_openBlock(), _createBlock(_component_overview_summary_item, {
                key: index,
                "summary-item": summaryItem,
                onJump: ($event: any) => (_ctx.jumpTo(summaryItem))
              }, null, 8, ["summary-item", "onJump"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["direction"])
      ]),
      _: 1
    })
  ]))
}