export const formatDate = (dateString: string) => {
    const parts = dateString.split("-");
    const d = parts[2];
    const m = parts[1];
    const y = parts[0];
    const months = [
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december",
    ];
    const month = months[parseInt(m) - 1];
    return [d, month, y].join(" ");
};
