
import { computed, defineComponent, ref } from "vue";
import DatePicker from "vue-datepicker-next";
import ErrorPopup from "../../../../../elements/ErrorPopup.vue";
import { MAX_OPTIONS } from "../../../../../../data/constants";
import { addDays, isWeekend } from "date-fns";
import { holidays } from "../../../../../../data/holidays";
import i18n from "../../../../../../i18n";

export default defineComponent({
    name: "PickDates",
    components: { ErrorPopup, DatePicker },
    props: {
        selection: {
            type: Array,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = i18n.global;
        const dates = ref([]);

        const maxOptions = MAX_OPTIONS.DATES;
        const displayPopup = ref(false);
        const closePopup = () => (displayPopup.value = false);
        const openPopup = () => (displayPopup.value = true);

        const tryOption = () => {
            const set: string[] = [];
            for (const key in dates.value) {
                set.push(dates.value[key]);
            }
            if (set.length > maxOptions) {
                delete dates.value[maxOptions];
                openPopup();
            } else {
                emit("select", {
                    dateDetail: set,
                    dateMethodKey: "specific-dates",
                });
            }
        };

        const chanceOfSuccess = computed(() => {
            const n = props.selection.length;
            if (n > 10) {
                return t(
                    "slides.SlideDates.details.methods.dates.succesOptions.big"
                );
            } else if (n > 4) {
                return t(
                    "slides.SlideDates.details.methods.dates.succesOptions.average"
                );
            } else if (n > 0) {
                return t(
                    "slides.SlideDates.details.methods.dates.succesOptions.small"
                );
            } else {
                return "";
            }
        });

        const disabledDates = (date: Date) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const lastUnavailableDate = addDays(
                today,
                window.config.daysNotAvailableFromToday
            );

            const endDate = addDays(today, 330);

            const isHoliday = (date: Date) => {
                const d = date.getDate();
                const m = date.getMonth() + 1;
                const string = m + "-" + d;
                return holidays.includes(string);
            };

            return (
                date < lastUnavailableDate ||
                date > endDate ||
                isWeekend(date) ||
                isHoliday(date)
            );
        };

        const firstAvailableDate = computed(() => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return addDays(today, window.config.daysNotAvailableFromToday);
        });

        return {
            dates,
            tryOption,
            maxOptions,
            displayPopup,
            closePopup,
            chanceOfSuccess,
            disabledDates,
            firstAvailableDate,
        };
    },
});
