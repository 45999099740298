
import { defineComponent, inject } from "vue";
import Subscription from "@/components/pages/checkout/subscription/Subscription.vue";
import HelpdeskButton from "@/components/elements/HelpdeskButton.vue";

export default defineComponent({
    name: "PaymentCancel",
    components: { HelpdeskButton, Subscription },
    setup() {
        const mq = inject("mq");

        return {
            mq,
        };
    },
});
