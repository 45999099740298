import { Dictionary } from "@/types";
import { SLIDE } from "../../constants";

const dict: Dictionary = {
    apply: "Toepassen",
    back: "Terug",
    close: "Sluiten",
    continue: "Doorgaan",
    edit: "Aanpassen",
    goto_payment: "Verder naar betalen",
    off: "Van",
    send: "Verzenden",
    step: "Stap",
    subscribe: "Inschrijven",
    total: "Totaal",
    pages: {
        checkout: {
            head: "Bedankt voor je aanmelding!",
            body: "Je aanmeldnummer is: {uuid}",
            addDiscount: "Ik heb een kortingscode",
        },
        subscription: {
            details: {
                head: "Jouw bestelling",
            },
        },
        authorize: {
            head: "Machtig 123-theorie",
            body: "Hieronder vind je de stappen om 123-theorie te machtigen voor jouw examen",
        },
        helpdesk: {
            head: "Klantenservice",
            body: "Heb je vragen? Je kunt ons bereiken via de telefoon, Whatsapp, Facebook of e-mail.",
        },
        payment: {
            success: {
                firstSubscription: {
                    body: "De betaling wordt gecontroleerd, je ontvangt over enkele minuten een bevestiging op {email}. Geen bevestiging ontvangen? Neem contact op via {telephone}",
                },
                reExam: {
                    body: "Hou de komende periode je mail goed in de gaten, op {email} ontvang jij alle informatie over je cursus.",
                },
                upsellExam: {
                    body: "Binnen enkele minuten zullen je examens in de applicatie verschijnen.",
                    button: "Terug naar de applicatie",
                },
                upsellUpgrade: {
                    body: "Binnen enkele minuten zullen de video's beschikbaar zijn in de applicatie.",
                    button: "Terug naar de applicatie",
                },
                upsellExtend: {
                    body: "Binnen enkele minuten zullen de video's beschikbaar zijn in de applicatie.",
                    button: "Terug naar de applicatie",
                },
            },
        },
    },
    slides: {
        [SLIDE.CBR_LOCATIONS]: {
            head: "Waar wil je je examen doen?",
            body: "<p>Kies hieronder alle steden waar je de examen wilt doen. Hoe meer locaties je selecteert, hoe groter de kans dat we jou op jouw voorkeursmoment kunnen inplannen.</p>",
        },
        [SLIDE.COURSE_TYPES]: {
            head: "Kies je cursus",
            body: "<p>Kies hieronder of je een dagcursus (theoriecursus op locatie) of een online cursus wilt volgen.</p>",
        },
        [SLIDE.DATES]: {
            head: "Wanneer wil je examen doen?",
            body: "Kies hieronder wanneer je examen wilt doen.",
            main: {
                methods: {
                    asap: {
                        head: "Zo snel mogelijk",
                        body: "Je wordt ingepland op het eerstvolgende plekje dat beschikbaar is. Dit is meestal binnen +/- 2 tot 4 weken.",
                    },
                    month: {
                        head: "Maand",
                        body: "Je wordt ingepland in de maand(en) die je gekozen hebt.",
                    },
                    vacation: {
                        head: "Vakantie",
                        body: "Je wordt ingepland in de vakantie(s) die je gekozen hebt.",
                    },
                    "specific-dates": {
                        head: "Kies zelf je datums",
                        body: "Kies zoveel mogelijk datums waarop je ingepland kunt worden. Hoe meer datums je selecteert, hoe groter de kans dat we jou op jouw voorkeursmoment kunnen inplannen.",
                    },
                },
            },
            details: {
                methods: {
                    months: {
                        head: "Kies een maand",
                        body: "Kies hieronder maximaal {maxOptions} maanden waarin je de cursus wilt volgen.",
                        warning:
                            "Je kunt maximaal {maxOptions} maanden kiezen.",
                    },
                    vacations: {
                        head: "Kies een vakantie",
                        body: "Kies hieronder alle vakanties waarin je de cursus wilt volgen.",
                        warning:
                            "Je kunt maximaal {maxOptions} vakanties kiezen.",
                    },
                    dates: {
                        head: "Kies een datum",
                        body: "<p>Geef de data op waarop je beschikbaar bent, hoe meer data je aangeeft des te groter de kans is dat wij je op deze data in kunnen plannen. De kans dat het lukt om je in te plannen op de door jou gekozen dagen staat onder de kalender.</p>",
                        successNote:
                            "Kans op examenplek bij het CBR: <strong>{chanceOfSuccess}</strong>",
                        succesOptions: {
                            big: "Groot",
                            average: "Gemiddeld",
                            small: "Klein",
                        },
                        warning: "Je kunt maximaal {maxOptions} data kiezen.",
                    },
                },
            },
        },
        [SLIDE.LOCATIONS]: {
            head: "Kies je locatie",
            body: "<p>Kies hieronder alle steden waar je de cursus wilt volgen. Hoe meer locaties je selecteert, hoe groter de kans dat we jou op jouw voorkeursmoment kunnen inplannen.</p>",
        },
        [SLIDE.OVERVIEW]: {
            // deze even dubbel om de test te laten slagen
            head: "Jouw gegevens",
            personalInfo: {
                head: "Jouw gegevens",
                body: "<p> Controleer hieronder jouw gegevens. Als er iets niet klopt, kun je dit aanpassen door op het pennetje te klikken. </p>",
                form: {
                    firstname: "Voornaam",
                    insertion: "Tussenvoegsel",
                    lastname: "Achternaam",
                    dob: "Geboortedatum",
                    email: "E-mailadres",
                    telephone: "Telefoonnummer",
                },
            },
            summary: {
                head: "Jouw cursus",
                body: "Controleer hieronder jouw cursus. Als je toch iets anders wilt, kun je dit aanpassen door op het pennetje te klikken.",
                summaryItem: {
                    [SLIDE.PRODUCT_CATEGORIES]: "Voertuig",
                    [SLIDE.ONLINE_PACKAGES]: "Jouw pakket",
                    [SLIDE.EXAM_TYPES]: "Jouw examen",
                    [SLIDE.DATES]: "Voorkeursdatum",
                    [SLIDE.COURSE_TYPES]: "Jouw cursus",
                    [SLIDE.CBR_LOCATIONS]: "Gekozen examenlocaties",
                    [SLIDE.LOCATIONS]: "Gekozen locaties",
                    [SLIDE.SELF_BOOKING]: "Examenlocatie",
                },
            },
            conditions: {
                head: "Algemene voorwaarden",
                body: "Om verder te gaan moet je akkoord gaan met de algemene voorwaarden en het privacy statement van 123-theorie.nl.",
                termsLinkText: "Algemene voorwaarden",
                privacyLinkText: "Privacy Statement",
                consentText:
                    "Ik ga akkoord met de algemene voorwaarden en het privacy statement en stem in met directe levering waardoor ik afzie van mijn bedenktijd.",
            },
            selfBookingLabel: {
                dateTime: "Eigen examen op {date} om {time}",
                date: "Eigen examen op {date}",
                none: "Eigen examen",
            },
        },
        [SLIDE.PRODUCT_CATEGORIES]: {
            head: "Kies je voertuig",
            body: "Kies hieronder voor welk voertuig je onze cursus wilt volgen.",
        },
        [SLIDE.ONLINE_PACKAGES]: {
            head: "Kies je pakket",
            body: "Kies hieronder hoe lang je wilt oefenen.",
            bodyForReExam:
                "<p>Kies hieronder hoe lang je wilt oefenen.</p><p><b>Let op: je korting wordt in de laatste stap verwerkt.</b></p>",
        },
        [SLIDE.PERSONAL_INFO]: {
            head: "Jouw gegevens",
            body: "Graag je voor- en achternaam exact zoals in je paspoort vermeld invullen.",
        },
        [SLIDE.EXAM_TYPES]: {
            head: "Kies je examen",
            body: "<p>Kies hieronder wat voor soort examen je bij de cursus wilt. <strong>Let op:</strong> als je zelf een examen bij het CBR hebt gereserveerd, kies dan voor <strong>eigen examen</strong>.</p>",
        },
        [SLIDE.SELF_BOOKING]: {
            head: "Eigen Examen",
            body: "Voor waar en wanneer heb jij jouw examen gereserveerd?",
        },
    },
};
export default dict;
