import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"font-size":"0.9375rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_price_discount = _resolveComponent("etd-price-discount")!
  const _component_etd_coming_soon = _resolveComponent("etd-coming-soon")!
  const _component_etd_checklist = _resolveComponent("etd-checklist")!
  const _component_etd_radio_deluxe = _resolveComponent("etd-radio-deluxe")!

  return (_openBlock(), _createBlock(_component_etd_radio_deluxe, {
    onClick: _ctx.select,
    "icon-id": _ctx.iconId,
    "icon-style": "duotone",
    title: _ctx.option.name,
    "is-active": _ctx.isActive,
    name: "product-category",
    value: _ctx.option.id,
    size: "regular",
    disabled: _ctx.option.disabled
  }, _createSlots({
    "title-extra": _withCtx(() => [
      (_ctx.showPrice && _ctx.option.current_price)
        ? (_openBlock(), _createBlock(_component_etd_price_discount, {
            key: 0,
            regular: _ctx.option.current_price.discount_label,
            discount: _ctx.option.current_price.formatted_price,
            size: "small"
          }, null, 8, ["regular", "discount"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.option.labels && _ctx.option.labels.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_etd_checklist, {
              list: _ctx.option.labels.map((label) => label.value),
              cols: _ctx.labelLength < 60 ? 2 : 1
            }, null, 8, ["list", "cols"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.showComingSoon)
      ? {
          name: "coming-soon",
          fn: _withCtx(() => [
            _createVNode(_component_etd_coming_soon)
          ])
        }
      : undefined
  ]), 1032, ["onClick", "icon-id", "title", "is-active", "value", "disabled"]))
}