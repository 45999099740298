import { OPTION_SLUGS, SLIDE, SLUGS } from "../data/constants";
import { OptionsState, PersonalInfoState } from "../types";

// function moved out of useSlides.ts to be able to test it with custom optionsState
export const getSlideNames = (
    optionsState: OptionsState,
    personalInfoState: PersonalInfoState,
    skipPreselected: boolean
) => {
    const isReExam = () => personalInfoState.subscription_type === "re-exam";

    const isOnline = () =>
        optionsState.selection[SLUGS.COURSE_TYPES]?.slug ===
        OPTION_SLUGS.ONLINE;

    const isSelfBooking = () =>
        optionsState.selection[SLUGS.EXAM_TYPES]?.slug ===
        OPTION_SLUGS.SELF_BOOKING;

    const isPreselected = (slug: string) => {
        return optionsState.preselectedOptionGroups.includes(slug);
    };

    const slides = [];

    if (!isPreselected(SLUGS.PRODUCT_CATEGORIES) || !skipPreselected) {
        slides.push(SLIDE.PRODUCT_CATEGORIES);
    }

    if (!isPreselected(SLUGS.COURSE_TYPES) || !skipPreselected) {
        slides.push(SLIDE.COURSE_TYPES);
    }
    slides.push(SLIDE.EXAM_TYPES);
    if (isOnline()) {
        if (!isSelfBooking()) {
            slides.push(SLIDE.CBR_LOCATIONS);
            slides.push(SLIDE.DATES);
        }
        slides.push(SLIDE.ONLINE_PACKAGES);
    } else {
        if (isSelfBooking()) {
            slides.push(SLIDE.SELF_BOOKING);
        } else {
            slides.push(SLIDE.LOCATIONS);
            slides.push(SLIDE.DATES);
        }
    }

    if (!isReExam()) {
        slides.push(SLIDE.PERSONAL_INFO);
    }

    slides.push(SLIDE.OVERVIEW);
    return slides;
};
