import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_icon = _resolveComponent("etd-icon")!

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args))),
    "aria-label": "Open helpdesk popup"
  }, [
    _createVNode(_component_etd_icon, {
      "icon-id": "headset",
      "icon-style": "duotone"
    })
  ]))
}