
import { defineComponent, PropType } from "vue";
import { SummaryItem } from "@/types";

export default defineComponent({
    name: "OverviewSummaryItem",
    props: {
        summaryItem: {
            type: Object as PropType<SummaryItem>,
            required: true,
        },
    },
    setup(props, { emit }) {
        return {
            jump: () => emit("jump"),
        };
    },
});
