
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
    name: "Info",
    setup() {
        const version = ref("...");

        const getVersion = async () => {
            const response = await fetch("/version.json");
            const json = await response.json();
            version.value = json.version;
        };

        onMounted(() => getVersion());

        return {
            version,
        };
    },
});
