import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_overview_personal_info = _resolveComponent("overview-personal-info")!
  const _component_overview_summary = _resolveComponent("overview-summary")!
  const _component_etd_container = _resolveComponent("etd-container")!
  const _component_overview_conditions = _resolveComponent("overview-conditions")!

  return (_openBlock(), _createBlock(_component_etd_container, { gap: "xxxl" }, {
    default: _withCtx(() => [
      _createVNode(_component_etd_container, {
        direction: _ctx.mq.t === 's' ? 'column' : 'row',
        gap: "xxl",
        n: _ctx.isReExam ? 1 : 2
      }, {
        default: _withCtx(() => [
          (!_ctx.isReExam)
            ? (_openBlock(), _createBlock(_component_overview_personal_info, {
                key: 0,
                "personal-info": _ctx.data.form
              }, null, 8, ["personal-info"]))
            : _createCommentVNode("", true),
          _createVNode(_component_overview_summary, {
            "summary-items": _ctx.data.summaryItems
          }, null, 8, ["summary-items"])
        ]),
        _: 1
      }, 8, ["direction", "n"]),
      _createVNode(_component_overview_conditions, {
        consent: _ctx.data.consent,
        onSetConsent: _ctx.atSetConsent
      }, null, 8, ["consent", "onSetConsent"])
    ]),
    _: 1
  }))
}