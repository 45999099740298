
import { computed, defineComponent } from "vue";
import { Option } from "@/types";
import { SLIDE } from "../../../../../data/constants";
import OptionGroup from "../OptionGroup.vue";
import { usePersonalInfoStore } from "@/store/personal-info";

export default defineComponent({
    name: SLIDE.ONLINE_PACKAGES,
    components: { OptionGroup },
    props: {
        data: {
            type: Object,
            required: true,
        },
        selection: {
            type: Object,
            required: false,
        },
    },
    setup(props, { emit }) {
        const personalInfoStore = usePersonalInfoStore();

        const entryHead = `slides.${SLIDE.ONLINE_PACKAGES}.head`;

        const entryBody = computed(() => {
            if (personalInfoStore.subscription_type === "re-exam") {
                return `slides.${SLIDE.ONLINE_PACKAGES}.bodyForReExam`;
            } else {
                return `slides.${SLIDE.ONLINE_PACKAGES}.body`;
            }
        });

        return {
            select: (item: Option) => emit("select", item),
            entryHead,
            entryBody,
        };
    },
});
