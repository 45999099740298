import { Route } from "@/types";
import PaymentUnknown from "@/components/pages/checkout/payment/PaymentUnknown.vue";
import PaymentFail from "@/components/pages/checkout/payment/PaymentFail.vue";
import PaymentCancel from "@/components/pages/checkout/payment/PaymentCancel.vue";
import PaymentSuccess from "@/components/pages/checkout/payment/PaymentSuccess.vue";

export const paymentSuccessRoutes: Route[] = [
    {
        // this is the url for data tracking. Do not change this.
        path: "/betaling-gelukt",
        name: "PaymentOfflineSuccess",
        component: PaymentSuccess,
        meta: {
            dataLayer: {
                event: "PaymentOfflineSuccess",
            },
        },
    },
    {
        // this is the url for data tracking. Do not change this.
        path: "/betaling-gelukt-online",
        name: "PaymentOnlineSuccess",
        component: PaymentSuccess,
        meta: {
            dataLayer: {
                event: "PaymentOnlineSuccess",
            },
        },
    },
    {
        path: "/aankoop-betaling-gelukt",
        name: "PaymentUpsellExamSuccess",
        component: PaymentSuccess,
        meta: {
            dataLayer: {
                event: "PaymentUpsellExamSuccess",
            },
        },
    },
    {
        path: "/aankoop-upgrade-gelukt",
        name: "PaymentUpsellUpgradeSuccess",
        component: PaymentSuccess,
        meta: {
            dataLayer: {
                event: "PaymentUpsellUpgradeSuccess",
            },
        },
    },
    {
        path: "/aankoop-extend-gelukt",
        name: "PaymentUpsellExtendSuccess",
        component: PaymentSuccess,
        meta: {
            dataLayer: {
                event: "PaymentUpsellExtendSuccess",
            },
        },
    },
    {
        path: "/herexamen-betaling-gelukt",
        name: "PaymentReExamSuccess",
        component: PaymentSuccess,
        meta: {
            dataLayer: {
                event: "PaymentReExamSuccess",
            },
        },
    },
];

export const paymentFailRoutes: Route[] = [
    {
        path: "/betaling-onbekend",
        name: "PaymentUnknown",
        component: PaymentUnknown,
    },
    {
        path: "/betaling-mislukt",
        name: "PaymentFail",
        component: PaymentFail,
    },
    {
        path: "/betaling-geannuleerd",
        name: "PaymentCancel",
        component: PaymentCancel,
    },
];
