
import { defineComponent, inject, ref, onMounted, computed } from "vue";
import Subscription from "@/components/pages/checkout/subscription/Subscription.vue";
import HelpdeskButton from "@/components/elements/HelpdeskButton.vue";
import {
    GoogleConversionData,
    Subscription as SubscriptionType,
} from "@/types";
import { scrollToTop } from "@/utils/scroll";
import { useRoute } from "vue-router";
import { ConversionRouteNames } from "@/types";
import {
    getConversionId,
    getConversionLabel,
    sendGoogleTag,
} from "@/utils/google";

export default defineComponent({
    name: "Checkout",
    components: { HelpdeskButton, Subscription },
    setup() {
        const mq = inject("mq");
        const uuid = ref<null | string>(null);
        const route = useRoute();
        const routeName = computed(() => route.name);
        const response = ref<SubscriptionType | null>(null);

        const atResponseSubscriptionData = (res: SubscriptionType) => {
            response.value = res;
            if (res.ga_payload) {
                const userDataPayload = {
                    sha256_email_address: res.sha256_email_address,
                    sha256_phone_number: res.sha256_phone_number,
                };
                const formSubmitPayload = {
                    send_to: getConversionId(),
                };
                const conversionLabel = getConversionLabel(
                    routeName.value as ConversionRouteNames
                );
                const conversionPayload: GoogleConversionData = {
                    send_to: conversionLabel,
                    value: res.ga_payload.value,
                    currency: res.ga_payload.currency,
                };

                sendGoogleTag("set", "user_data", userDataPayload);
                sendGoogleTag("set", "form_submit", formSubmitPayload);
                sendGoogleTag("event", "conversion", conversionPayload);
            }
        };

        onMounted(() => {
            setTimeout(() => {
                scrollToTop();
            }, 1000);
        });

        return {
            mq,
            uuid,
            response,
            atResponseSubscriptionData,
        };
    },
});
