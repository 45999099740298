import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d783228"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "OverviewSummaryItem" }
const _hoisted_2 = { class: "OverviewSummaryItem__label" }
const _hoisted_3 = { class: "OverviewSummaryItem__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_icon = _resolveComponent("etd-icon")!
  const _component_etd_icon_button = _resolveComponent("etd-icon-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_etd_icon, {
      key: _ctx.summaryItem.icon,
      "icon-id": _ctx.summaryItem.icon,
      "icon-style": "duotone"
    }, null, 8, ["icon-id"])),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(
                        "slides.SlideOverview.summary.summaryItem." +
                            _ctx.summaryItem.component
                    )), 1),
      _createTextVNode(" " + _toDisplayString(_ctx.summaryItem.label), 1)
    ]),
    _createVNode(_component_etd_icon_button, {
      onClick: _ctx.jump,
      "icon-id": "pen-to-square",
      color: "blue-5"
    }, null, 8, ["onClick"])
  ]))
}