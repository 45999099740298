import { defineStore } from "pinia";
import {
    CbrLocation,
    DateDetail,
    DateMethod,
    DateString,
    ExamState,
    Location,
    Month,
    Vacation,
} from "@/types";
import { toggleInArray } from "@/utils/array";

export const useExamStore = defineStore("exam", {
    state: () => {
        const state: ExamState = {
            locations: [],
            cbrLocations: [],
            dates: {
                methods: [],
                months: [],
                vacations: [],
            },
            selection: {
                locations: [],
                cbrLocations: [],
                dates: {
                    method: null,
                    details: [],
                    specificDates: [],
                },
            },
        };
        return state;
    },
    getters: {},
    actions: {
        toggleLocation(location: Location) {
            toggleInArray(this.selection.locations, location);
        },
        toggleCbrLocation(cbrLocation: CbrLocation) {
            toggleInArray(this.selection.cbrLocations, cbrLocation);
        },
        selectDateMethod(dateMethod: DateMethod) {
            if (dateMethod !== this.selection.dates.method) {
                // reset choices
                this.selection.dates.details = [];
                this.selection.dates.specificDates = [];
            }
            this.selection.dates.method = dateMethod;
        },
        selectDateDetails(dateDetails: DateDetail[]) {
            if (this.selection.dates.method?.slug === "specific-dates") {
                this.selection.dates.specificDates =
                    dateDetails as DateString[];
            } else {
                this.selection.dates.details = dateDetails as (
                    | Month
                    | Vacation
                )[];
            }
        },
    },
});
