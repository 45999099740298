import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { "data-locator": "slide-head" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_location = _resolveComponent("location")!
  const _component_etd_container = _resolveComponent("etd-container")!
  const _component_slide_body = _resolveComponent("slide-body")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mq.t === 's' ? 'div' : 'etd-card'), {
    padding: "xxxxl",
    "border-radius": "xl"
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.entry + "head")), 1),
      _createVNode(_component_etd_container, { gap: "l" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            innerHTML: _ctx.$t(_ctx.entry + 'body')
          }, null, 8, _hoisted_2),
          _createVNode(_component_slide_body, null, {
            default: _withCtx(() => [
              _createVNode(_component_etd_container, {
                direction: "row",
                gap: _ctx.mq.t === 's' ? 'l' : 'xl xxxl',
                wrap: true,
                n: _ctx.mq.t === 's' ? 1 : 2
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.items, (item, index) => {
                    return (_openBlock(), _createBlock(_component_location, {
                      onClick: ($event: any) => (_ctx.select(item)),
                      key: index,
                      "is-active": _ctx.selection.items.includes(item),
                      location: item,
                      label: "address"
                    }, null, 8, ["onClick", "is-active", "location"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["gap", "n"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}