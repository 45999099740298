import { watch, ref } from "vue";
import { useOptionsStore } from "@/store/options";
import { usePersonalInfoStore } from "@/store/personal-info";
import { useUiStore } from "@/store/ui";
import { useRoute, useRouter } from "vue-router";
import useSlides from "@/composables/useSlides";

export default function useQuery() {
    const optionsStore = useOptionsStore();
    const personalInfoStore = usePersonalInfoStore();
    const uiStore = useUiStore();
    const router = useRouter();
    const route = useRoute();
    const { slides } = useSlides();

    const blockWatching = ref(false);

    const updateUrl = () => {
        const query: any = {};
        for (const key in optionsStore.selection) {
            query[key] = optionsStore.selection[key].slug;
        }
        if (personalInfoStore.reExamID.length > 0) {
            query.reExamID = personalInfoStore.reExamID;
        }
        query.slide = uiStore.currentSlide + 1;
        blockWatching.value = true;
        setTimeout(() => {
            blockWatching.value = false;
        }, 500);
        router.push({ name: "Planner", query });
    };

    watch(
        () => route.query.slide,
        (oldSlide, newSlide) => {
            if (!blockWatching.value) {
                if (Number(newSlide) !== Number(oldSlide)) {
                    const slideIndex = Number(oldSlide) - 1;
                    if (slideIndex >= 0 && slideIndex < slides.value.length) {
                        uiStore.currentSlide = slideIndex;
                    }
                }
            }
        }
    );

    return {
        updateUrl,
    };
}
