
import { defineComponent, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { api } from "@/api";
import HelpdeskButton from "@/components/elements/HelpdeskButton.vue";
import {
    ConversionRouteNames,
    GoogleConversionData,
    PaymentType,
    PollResult,
} from "@/types";
import { usePersonalInfoStore } from "@/store/personal-info";
import { getConversionLabel, sendGoogleTag } from "@/utils/google";

export default defineComponent({
    name: "PaymentCheck",
    components: { HelpdeskButton },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const personalInfoStore = usePersonalInfoStore();

        const subscription_hash = route.query.subscription;
        const transaction_hash = route.query.transaction;

        const paymentTypeToRouteName = (
            type: PaymentType
        ): ConversionRouteNames => {
            switch (type) {
                case "re-exam-online":
                case "re-exam-offline":
                    return "PaymentReExamSuccess";
                case "exam-upsell":
                    return "PaymentUpsellExamSuccess";
                case "upgrade-upsell":
                    return "PaymentUpsellUpgradeSuccess";
                case "extend-upsell":
                    return "PaymentUpsellExtendSuccess";
                case "new-online":
                    return "PaymentOnlineSuccess";
                case "new-offline":
                default:
                    return "PaymentOfflineSuccess";
            }
        };

        const handleSuccess = (result: PollResult) => {
            const routeName = paymentTypeToRouteName(result.type);
            if (result.ga_payload) {
                const conversionLabel = getConversionLabel(
                    routeName as ConversionRouteNames
                );
                const conversionPayload: GoogleConversionData = {
                    send_to: conversionLabel,
                    value: result.ga_payload.value,
                    currency: result.ga_payload.currency,
                };
                sendGoogleTag("event", "conversion", conversionPayload);
                sendGoogleTag("event", "purchase", result.ga_payload);
            }
            router.push({
                name: routeName,
                query: {
                    machtigen: String(result.show_authorization_steps),
                },
            });
        };

        if (subscription_hash && transaction_hash) {
            api.startPoll(subscription_hash, transaction_hash).then(
                (result: PollResult) => {
                    personalInfoStore.form.email = result.email;
                    switch (result.status) {
                        case "success":
                            handleSuccess(result);
                            break;
                        case "failed":
                            router.push({
                                name: "PaymentFail",
                                query: {
                                    hash: subscription_hash,
                                    machtigen: String(
                                        result.show_authorization_steps
                                    ),
                                },
                            });
                            break;
                        case "cancel":
                            router.push({
                                name: "PaymentCancel",
                                query: {
                                    hash: subscription_hash,
                                    machtigen: String(
                                        result.show_authorization_steps
                                    ),
                                },
                            });
                            break;
                        default:
                            router.push({
                                name: "PaymentUnknown",
                                query: { hash: subscription_hash },
                            });
                            break;
                    }
                }
            );
        } else {
            router.push({ name: "Planner" });
        }

        const mq = inject("mq");

        return {
            mq,
        };
    },
});
