
import { defineComponent } from "vue";
import useSlides from "../../../../composables/useSlides";

export default defineComponent({
    name: "SliderProgress",
    components: {},
    setup() {
        const { slides, currentSlide } = useSlides();

        return {
            currentSlide,
            slides,
        };
    },
});
