
import { defineComponent, inject } from "vue";
import SlideBody from "../../../slider/SlideBody.vue";
import ErrorPopup from "../../../../../elements/ErrorPopup.vue";
import useMaxOptions from "../../../../../../composables/useMaxOptions";
import { Month, Vacation } from "../../../../../../types";
import { MAX_OPTIONS } from "../../../../../../data/constants";

export default defineComponent({
    name: "DateDetail",
    components: { SlideBody, ErrorPopup },
    props: {
        dateMethodKey: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        selection: {
            type: Array,
            required: true,
        },
    },
    setup(props, { emit }) {
        const mq = inject("mq");

        const maxOptions = MAX_OPTIONS[props.dateMethodKey.toUpperCase()];

        const selectOption = (dateDetail: Month | Vacation) => {
            emit("select", {
                dateDetail,
                dateMethodKey: props.dateMethodKey,
            });
        };

        const { displayPopup, closePopup, tryOption } = useMaxOptions(
            maxOptions,
            props.selection,
            selectOption
        );

        return {
            tryOption,
            maxOptions,
            displayPopup,
            closePopup,
            mq,
        };
    },
});
