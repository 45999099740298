
import { computed, defineComponent, inject, PropType, ref } from "vue";
import DateMethods from "./methods/DateMethods.vue";
import PickDates from "./pickers/PickDates.vue";
import DateDetail from "./pickers/DateDetail.vue";
import SlideBody from "../../slider/SlideBody.vue";
import { SLIDE } from "../../../../../data/constants";
import {
    DateMethod as DateMethodType,
    DateDetail as DateDetailType,
    Month,
    Vacation,
    DateString,
} from "@/types";

interface DatesData {
    dateMethods: DateMethodType[];
    dateDetailItems: {
        month: Month[];
        vacation: Vacation[];
        "specific-dates": DateString[];
    };
}

interface DatesSelection {
    dateMethod: DateMethodType;
}

export default defineComponent({
    name: SLIDE.DATES,
    components: { SlideBody, DateDetail, PickDates, DateMethods },
    props: {
        data: {
            type: Object as PropType<DatesData>,
            required: true,
        },
        selection: {
            type: Object as PropType<DatesSelection>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const mq = inject("mq");
        const entry = `slides.${SLIDE.DATES}.`;

        const localDateMethod = ref<DateMethodType | null>(
            props.selection.dateMethod
        );

        const localDateDetails = ref<DateDetailType[]>([]);

        const atSelectDateMethod = (dateMethod: DateMethodType) => {
            // reset details
            localDateDetails.value = [];
            localDateMethod.value = dateMethod;
            emit("select", dateMethod);
        };

        const atSelectDateDetail = (payload: any) => {
            if (payload.dateMethodKey === "specific-dates") {
                localDateDetails.value = [...payload.dateDetail];
            } else {
                const index = localDateDetails.value.indexOf(
                    payload.dateDetail
                );
                if (index === -1) {
                    localDateDetails.value.push(payload.dateDetail);
                } else {
                    localDateDetails.value.splice(index, 1);
                }
            }

            emit("select-detail", localDateDetails.value);
        };

        const showDateDetail = computed(
            () => localDateMethod.value && localDateMethod.value.slug !== "asap"
        );

        const dateDetailComponentName = computed(() => {
            if (localDateMethod.value === null) {
                return "";
            } else {
                if (localDateMethod.value.slug === "specific-dates") {
                    return "pick-dates";
                } else {
                    return "date-detail";
                }
            }
        });

        return {
            localDateMethod,
            localDateDetails,
            atSelectDateMethod,
            atSelectDateDetail,
            showDateDetail,
            dateDetailComponentName,
            mq,
            entry,
        };
    },
});
