
import { defineComponent, PropType } from "vue";
import { Location } from "@/types";

export default defineComponent({
    name: "Location",
    props: {
        location: {
            type: Object as PropType<Location>,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    isActive: {
        type: Boolean,
        required: true,
    },
});
