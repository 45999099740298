import { defineStore } from "pinia";
import { OptionsState, Option } from "@/types";
import { getOptionGroupById } from "../utils/base-products";
import { OPTION_SLUGS, SLUGS } from "@/data/constants";
import { useExamStore } from "@/store/exam";
import { usePersonalInfoStore } from "@/store/personal-info";

export const useOptionsStore = defineStore("options", {
    state: () => {
        const state: OptionsState = {
            preselectedOptionGroups: [],
            baseProducts: [],
            selection: {},
        };
        return state;
    },
    getters: {
        getSelectedOptions: (state: OptionsState) => {
            const options = [];
            for (const slug in state.selection) {
                options.push(state.selection[slug]);
            }
            return options;
        },
    },
    actions: {
        preSelectOption(option: Option) {
            this.selectOption(option);
            // this leaves info for getSlideNames() to hide slides
            const optionGroup = getOptionGroupById(
                this.baseProducts,
                option.option_group_id
            );
            if (optionGroup) {
                const slug = optionGroup.slug;
                if (!this.preselectedOptionGroups.includes(slug)) {
                    this.preselectedOptionGroups.push(slug);
                }
            }
        },
        clearPreselection() {
            this.preselectedOptionGroups = [];
        },
        selectOption(option: Option) {
            const examStore = useExamStore();
            const personalInfoStore = usePersonalInfoStore();

            const cleanUpState = () => {
                // delete state of the online path
                if (
                    option.slug === OPTION_SLUGS.MOTOR ||
                    option.slug === OPTION_SLUGS.MOPED
                ) {
                    delete this.selection[SLUGS.COURSE_TYPES];
                    delete this.selection[SLUGS.ONLINE_PACKAGES];
                }

                // delete cbr locations
                if (
                    option.slug === OPTION_SLUGS.OFFLINE ||
                    option.slug === OPTION_SLUGS.SELF_BOOKING
                ) {
                    examStore.selection.cbrLocations = [];
                }

                // delete regular locations
                if (option.slug === OPTION_SLUGS.SELF_BOOKING) {
                    examStore.selection.locations = [];
                }

                // clear self booking
                if (
                    option.slug === OPTION_SLUGS.REGULAR ||
                    option.slug === OPTION_SLUGS.EXTENDED
                ) {
                    personalInfoStore.selfBooking = {
                        location: null,
                        date: "",
                        time: "",
                    };
                }
            };

            cleanUpState();

            const optionGroup = getOptionGroupById(
                this.baseProducts,
                option.option_group_id
            );
            if (optionGroup) {
                this.selection[optionGroup.slug] = option;
            }
        },
    },
});
