
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
    name: "Sticky",
    props: {
        side: {
            type: String,
            required: false,
            default: "bottom",
        },
    },
    setup() {
        const element = ref<HTMLElement>();

        const isSticky = ref(false);

        const isScrolledIntoView = (el: HTMLElement) => {
            const rect = el.getBoundingClientRect();
            const elemTop = rect.top;
            const elemBottom = rect.bottom;

            return elemTop >= 0 && elemBottom <= window.innerHeight;
        };

        onMounted(() => {
            if (element.value) {
                addEventListener("scroll", () => {
                    if (element.value) {
                        isSticky.value = !isScrolledIntoView(element.value);
                    }
                });
            }
        });

        return {
            element,
            isSticky,
        };
    },
});
