import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import etdPlugin from "@innouveau/123-etd";
import i18n from "./i18n";
import { createPinia } from "pinia";

import "vue-datepicker-next/index.css";
import "vue-select/dist/vue-select.css";

const app = createApp(App);

app.use(createPinia()).use(router).use(etdPlugin).use(i18n);
app.mount("#planmodule");
