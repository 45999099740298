import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"margin-top":"var(--spacing-l)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_form_error_message = _resolveComponent("etd-form-error-message")!
  const _component_helpdesk_content = _resolveComponent("helpdesk-content")!
  const _component_etd_button = _resolveComponent("etd-button")!
  const _component_etd_container = _resolveComponent("etd-container")!
  const _component_etd_popup = _resolveComponent("etd-popup")!

  return (_openBlock(), _createBlock(_component_etd_popup, null, {
    body: _withCtx(() => [
      _createVNode(_component_etd_form_error_message, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }),
      (_ctx.enableHelpdesk)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_helpdesk_content)
          ]))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_etd_container, {
        direction: "row",
        justify: "end"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_etd_button, { onClick: _ctx.close }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("close")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 3
  }))
}