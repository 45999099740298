import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_radio_deluxe = _resolveComponent("etd-radio-deluxe")!

  return (_openBlock(), _createBlock(_component_etd_radio_deluxe, {
    "is-active": _ctx.isActive,
    onClick: _ctx.select,
    title: _ctx.$t(_ctx.key + 'head'),
    name: "date-method",
    value: _ctx.dateMethod.slug,
    size: "regular"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.key + "body")), 1)
    ]),
    _: 1
  }, 8, ["is-active", "onClick", "title", "value"]))
}