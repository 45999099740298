import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e412e1d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PickDates" }
const _hoisted_2 = { class: "PickDates__content" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "PickDates__picker" }
const _hoisted_5 = { class: "PickDates__picker-container" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_error_popup = _resolveComponent("error-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("slides.SlideDates.details.methods.dates.head")), 1),
        _createElementVNode("div", {
          innerHTML: _ctx.$t('slides.SlideDates.details.methods.dates.body')
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_date_picker, {
            value: _ctx.dates,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dates) = $event)),
            "default-value": _ctx.firstAvailableDate,
            multiple: true,
            "disabled-date": _ctx.disabledDates,
            "value-type": 'YYYY-MM-DD',
            open: true,
            "append-to-body": false,
            "popup-style": { left: 0, top: 0 },
            onChange: _ctx.tryOption
          }, null, 8, ["value", "default-value", "disabled-date", "onChange"])
        ]),
        (_ctx.selection.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "PickDates__chance",
              innerHTML: 
                    _ctx.$t('slides.SlideDates.details.methods.dates.successNote', {
                        chanceOfSuccess: _ctx.chanceOfSuccess,
                    })
                
            }, null, 8, _hoisted_6))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.displayPopup)
      ? (_openBlock(), _createBlock(_component_error_popup, {
          key: 0,
          onClose: _ctx.closePopup
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("slides.SlideDates.details.methods.dates.warning")), 1)
          ]),
          _: 1
        }, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}