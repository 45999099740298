import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_option_group = _resolveComponent("option-group")!

  return (_ctx.data.optionGroup)
    ? (_openBlock(), _createBlock(_component_option_group, {
        key: 0,
        onSelectOption: _ctx.select,
        title: _ctx.$t(_ctx.entryHead),
        "option-group": _ctx.data.optionGroup,
        selection: _ctx.selection
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            innerHTML: _ctx.$t(_ctx.entryBody)
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["onSelectOption", "title", "option-group", "selection"]))
    : _createCommentVNode("", true)
}