
import { computed, defineComponent, inject, PropType, ref } from "vue";
import { CbrLocation } from "@/types";
import SlideBody from "../../slider/SlideBody.vue";
import { SLIDE } from "../../../../../data/constants";
import { usePersonalInfoStore } from "../../../../../store/personal-info";

interface SelfBookingData {
    locations: CbrLocation[];
}

export default defineComponent({
    name: SLIDE.SELF_BOOKING,
    components: { SlideBody },
    props: {
        data: {
            type: Object as PropType<SelfBookingData>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const mq: any = inject("mq");
        const entry = `slides.${SLIDE.SELF_BOOKING}.`;
        const personalInfoStore = usePersonalInfoStore();

        const atUpdate = () => emit("select", formValue.value);

        const formValue = ref({
            ...personalInfoStore.selfBooking,
        });

        const formConfig = computed(() => {
            const settings = {
                displayRequiredMark: true,
                displayPositiveFeedback: true,
                validateAt: "blur",
            };
            const location = {
                key: "location",
                label: "Locatie",
                placeholder: "Kies locatie...",
                required: true,
                type: "select",
                editable: true,
                validation: [
                    {
                        hint: "Kies een locatie",
                    },
                ],
                labelKey: "address",
                valueKey: "id",
                options: props.data.locations,
                settings: {
                    maxHeight: 180,
                },
            };
            const date = {
                key: "date",
                label: "Datum",
                placeholder: "Datum",
                required: false,
                type: "date",
                editable: true,
            };
            const time = {
                key: "time",
                label: "Tijdstip",
                placeholder: "uu:mm",
                required: false,
                type: "text",
                editable: true,
                reformatter: (timeString: string) => {
                    function isValidTimeWithoutColon(timeString: string) {
                        // Check if the input consists of 3 or 4 digits
                        if (/^\d{3,4}$/.test(timeString)) {
                            // Pad the string to ensure it has 4 digits (e.g., "700" -> "0700")
                            const paddedTime = timeString.padStart(4, "0");

                            // Extract hours and minutes
                            const hours = parseInt(
                                paddedTime.substring(0, 2),
                                10
                            );
                            const minutes = parseInt(
                                paddedTime.substring(2, 4),
                                10
                            );

                            // Check if the hours and minutes are within valid ranges
                            if (
                                hours >= 7 &&
                                hours < 24 &&
                                minutes >= 0 &&
                                minutes < 60
                            ) {
                                return true; // It's a valid time without a colon
                            }
                        }
                        return false; // Not a valid time without a colon
                    }

                    if (isValidTimeWithoutColon(timeString)) {
                        const paddedTime =
                            timeString.length === 3
                                ? timeString.padStart(4, "0")
                                : timeString;
                        return (
                            paddedTime.substring(0, 2) +
                            ":" +
                            paddedTime.substring(2, 4)
                        );
                    } else {
                        return timeString;
                    }
                },
            };

            const xlVariant = [
                {
                    items: [location, date, time],
                },
            ];

            const regularVariant = [
                { items: [location] },
                { items: [date] },
                { items: [time] },
            ];

            return {
                settings,
                cards: [
                    {
                        type: "transparent",
                        sections: [
                            {
                                type: "regular",
                                rows:
                                    mq.value.i > 1 ? xlVariant : regularVariant,
                            },
                        ],
                    },
                ],
            };
        });

        return {
            formValue,
            formConfig,
            atUpdate,
            mq,
            entry,
        };
    },
});
