import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { Route } from "@/types";
import { sendGoogleTag } from "@/utils/google";

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const toRoute = to as unknown as Route;
    if (toRoute.meta) {
        if (toRoute.meta.dataLayer) {
            // not sure if we use this payload (or even the events)
            sendGoogleTag("event", toRoute.meta.dataLayer.event, {
                pageTitle: toRoute.name,
            });
        }
    }
    next();
});

export default router;
