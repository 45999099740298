
import { defineComponent } from "vue";
import { SLIDE } from "../../../../../data/constants";
import OptionGroup from "../OptionGroup.vue";
import { Option } from "@/types";

export default defineComponent({
    name: SLIDE.COURSE_TYPES,
    components: { OptionGroup },
    props: {
        data: {
            type: Object,
            required: true,
        },
        selection: {
            type: Object,
            required: false,
        },
    },
    setup(props, { emit }) {
        const entry = `slides.${SLIDE.COURSE_TYPES}.`;

        return {
            select: (item: Option) => emit("select", item),
            entry,
        };
    },
});
