import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { "data-locator": "slide-head" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_option_group_option = _resolveComponent("option-group-option")!
  const _component_etd_container = _resolveComponent("etd-container")!
  const _component_slide_body = _resolveComponent("slide-body")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mq.t === 's' ? 'div' : 'etd-card'), {
    padding: "xxxxl",
    "border-radius": "xl"
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_component_slide_body, null, {
        default: _withCtx(() => [
          _createVNode(_component_etd_container, {
            direction: "row",
            gap: _ctx.mq.t === 's' ? 'l' : 'xl xxxl',
            n: _ctx.mq.t === 's' ? 1 : _ctx.cols,
            wrap: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionGroup.options, (option, index) => {
                return (_openBlock(), _createBlock(_component_option_group_option, {
                  onSelect: _ctx.select,
                  key: index,
                  "is-active": _ctx.selection === option,
                  option: option,
                  "show-price": _ctx.optionGroup.show_pm_prices
                }, null, 8, ["onSelect", "is-active", "option", "show-price"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["gap", "n"])
        ]),
        _: 1
      })
    ]),
    _: 3
  }))
}