
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useUiStore } from "@/store/ui";
import { measureSlider } from "@/utils/measure";

export default defineComponent({
    name: "Slide",
    props: {
        i: {
            type: Number,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const uiStore = useUiStore();
        const main = ref<HTMLElement>();

        const preventTabbing = computed(() => uiStore.currentSlide !== props.i);

        const updateTabPrevention = () => {
            setTimeout(() => {
                if (main.value) {
                    const buttons = main.value.getElementsByTagName("button");
                    const inputs = main.value.getElementsByTagName("input");
                    const textareas =
                        main.value.getElementsByTagName("textarea");
                    const elements = [...buttons, ...inputs, ...textareas];
                    for (const element of elements) {
                        if (preventTabbing.value) {
                            element.tabIndex = -1;
                        } else {
                            element.tabIndex = 0;
                        }
                    }
                }
            });
        };

        watch(
            () => props.i,
            () => {
                updateTabPrevention();
            }
        );

        watch(
            () => props.id,
            () => {
                updateTabPrevention();
            }
        );

        watch(
            () => preventTabbing.value,
            () => {
                updateTabPrevention();
            }
        );

        onMounted(() => {
            updateTabPrevention();
        });

        return {
            main,
            preventTabbing,
        };
    },
});
