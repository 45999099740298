import { ref } from "vue";

export default function useMaxOptions(
    maxOptions: number,
    options: any,
    toggleOption: (option: any) => void
) {
    const displayPopup = ref(false);
    const closePopup = () => (displayPopup.value = false);

    const tryOption = (option: any) => {
        if (options.includes(option) || options.length < maxOptions) {
            toggleOption(option);
        } else {
            displayPopup.value = true;
        }
    };

    return {
        displayPopup,
        closePopup,
        tryOption,
    };
}
