import { ref } from "vue";
import { ErrorResponse } from "@/types";

export default function useErrorPopup() {
    const displayPopup = ref(false);
    const message = ref("");
    const enableHelpdesk = ref(false);

    const openPopup = (error: ErrorResponse) => {
        const errors = [];
        if (error.response.data.errors) {
            for (const key in error.response.data.errors) {
                for (const item of error.response.data.errors[key]) {
                    errors.push(item);
                }
            }
            if (error.response.data.errors.email) {
                enableHelpdesk.value = true;
            } else {
                enableHelpdesk.value = false;
            }

            message.value = errors.join("<br>");
        } else {
            message.value = error.response.data.message;
        }

        displayPopup.value = true;
    };
    const closePopup = () => (displayPopup.value = false);

    return {
        enableHelpdesk,
        displayPopup,
        message,
        openPopup,
        closePopup,
    };
}
