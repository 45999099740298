
import { defineComponent } from "vue";
import { useUiStore } from "@/store/ui";
import HelpdeskContent from "@/components/elements/HelpdeskContent.vue";

export default defineComponent({
    name: "HelpdeskPopup",
    components: { HelpdeskContent },
    setup() {
        const uiStore = useUiStore();

        return {
            uiStore,
            close: () => (uiStore.displayHelpdeskPopup = false),
        };
    },
});
