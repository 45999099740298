import { DateMethod } from "@/types";
import { DATE_METHOD_SELF_BOOKING_SLUG, OPTION_SLUGS } from "@/data/constants";

export const dateMethods: DateMethod[] = [
    {
        id: null,
        uuid: "efe0e696fc10a34ecff50d56c5bc6c22",
        display: true,
        type: "random",
        slug: "asap",
        hideFor: [],
    },
    {
        id: null,
        uuid: "",
        display: true,
        type: "month",
        slug: "month",
        hideFor: [],
    },
    {
        id: null,
        uuid: "",
        display: true,
        type: "vacation",
        slug: "vacation",
        hideFor: [],
    },
    {
        id: null,
        uuid: "1c8b19af4da1b9fc771fca78669b12c5",
        display: true,
        type: "random",
        slug: "specific-dates",
        hideFor: [OPTION_SLUGS.ONLINE],
    },
    {
        // Eigen examen
        id: null,
        uuid: "bc67a1d5ffbb7e82b19a1212832173ce",
        display: false,
        type: "random",
        slug: DATE_METHOD_SELF_BOOKING_SLUG,
        hideFor: [],
    },
];
