import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_helpdesk_content = _resolveComponent("helpdesk-content")!
  const _component_etd_popup = _resolveComponent("etd-popup")!

  return (_ctx.uiStore.displayHelpdeskPopup)
    ? (_openBlock(), _createBlock(_component_etd_popup, {
        key: 0,
        "close-button": "",
        onClose: _ctx.close
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("pages.helpdesk.head")), 1)
        ]),
        body: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("pages.helpdesk.body")), 1),
          _createVNode(_component_helpdesk_content)
        ]),
        _: 1
      }, 8, ["onClose"]))
    : _createCommentVNode("", true)
}