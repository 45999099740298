
import { defineComponent, inject, PropType } from "vue";
import { SLIDE } from "../../../../../data/constants";
import { Location as LocationType } from "@/types";
import Location from "../location/Location.vue";
import ErrorPopup from "../../../../elements/ErrorPopup.vue";

interface LocationsData {
    items: LocationType[];
}

interface LocationsSelection {
    items: LocationType[];
}

export default defineComponent({
    name: SLIDE.CBR_LOCATIONS,
    components: { ErrorPopup, Location },
    props: {
        data: {
            type: Object as PropType<LocationsData>,
            required: true,
        },
        selection: {
            type: Object as PropType<LocationsSelection>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const mq = inject("mq");
        const entry = `slides.${SLIDE.CBR_LOCATIONS}.`;

        const select = (item: LocationType) => emit("select", item);

        return {
            select,
            mq,
            entry,
        };
    },
});
