
import { defineComponent, inject, ref } from "vue";
import Slider from "@/components/pages/planner/slider/Slider.vue";
import { useRoute } from "vue-router";
import { api } from "@/api";
import SliderTools from "@/components/pages/planner/slider/SliderTools.vue";
import SliderProgress from "@/components/pages/planner/slider/SliderProgress.vue";
import HelpdeskButton from "@/components/elements/HelpdeskButton.vue";
import { useOptionsStore } from "@/store/options";
import { usePersonalInfoStore } from "@/store/personal-info";
import { useExamStore } from "@/store/exam";
import { FormData, FormDataSelection } from "@/types";
import { SLUGS } from "@/data/constants";
import { getOptionGroupBySlug } from "@/utils/base-products";
import { transformPeriods } from "@/utils/dates";

export default defineComponent({
    name: "Planner",
    components: {
        HelpdeskButton,
        SliderProgress,
        SliderTools,
        Slider,
    },
    props: {},
    setup() {
        const error = ref<null | Error>(null);
        const optionsStore = useOptionsStore();
        const personalInfoStore = usePersonalInfoStore();
        const examStore = useExamStore();
        const isLoaded = ref(false);
        const route = useRoute();

        const mq = inject("mq");

        const getReExamId = () => {
            if (route.query && route.query.reExamID) {
                return route.query.reExamID;
            } else {
                return "";
            }
        };

        const readUrl = () => {
            // eg http://localhost:8080/?product_categories=11&course_types=10&exam_types=6&online_packages=14
            const slugs = [
                SLUGS.PRODUCT_CATEGORIES,
                SLUGS.COURSE_TYPES,
                SLUGS.EXAM_TYPES,
                SLUGS.ONLINE_PACKAGES,
            ];
            const baseProducts = optionsStore.baseProducts;
            for (const slug of slugs) {
                if (route.query[slug]) {
                    const optionGroup = getOptionGroupBySlug(
                        baseProducts,
                        slug,
                        optionsStore.selection
                    );
                    if (optionGroup) {
                        const optionSlug = route.query[slug];
                        const option = optionGroup.options.find(
                            (o) => o.slug === optionSlug
                        );
                        if (option) {
                            optionsStore.preSelectOption(option);
                        }
                    }
                }
            }
        };

        const preselectOptions = (selection: FormDataSelection) => {
            for (const slug in selection) {
                const optionGroup = getOptionGroupBySlug(
                    optionsStore.baseProducts,
                    slug,
                    optionsStore.selection
                );
                if (optionGroup) {
                    const optionId = selection[slug];
                    const option = optionGroup.options.find(
                        (o) => o.id === optionId
                    );
                    if (option) {
                        optionsStore.preSelectOption(option);
                    }
                }
            }
        };

        personalInfoStore.reExamID = getReExamId() as string;

        api.bootstrap(personalInfoStore.reExamID)
            .then((response: FormData) => {
                error.value = null;
                optionsStore.baseProducts = response.products;
                personalInfoStore.subscription_type =
                    response.subscription_type;
                examStore.locations = response.locations;
                examStore.cbrLocations = response.cbr_locations;
                examStore.dates = transformPeriods(response.periods);
                preselectOptions(response.selection);
                readUrl();
            })
            .catch((err: Error) => {
                console.log(err);
                error.value = err;
            })
            .finally(() => {
                isLoaded.value = true;
            });

        return {
            mq,
            isLoaded,
            error,
        };
    },
});
