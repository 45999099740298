
import { computed, defineComponent, inject, PropType } from "vue";
import OverviewSummary from "./OverviewSummary.vue";
import { SummaryItem, PersonalInfo } from "@/types";
import OverviewPersonalInfo from "./OverviewPersonalInfo.vue";
import OverviewConditions from "./OverviewConditions.vue";
import { SLIDE } from "../../../../../data/constants";
import { usePersonalInfoStore } from "@/store/personal-info";

interface OverviewData {
    summaryItems: SummaryItem[];
    form: PersonalInfo;
    consent: boolean;
}

export default defineComponent({
    name: SLIDE.OVERVIEW,
    components: {
        OverviewPersonalInfo,
        OverviewSummary,
        OverviewConditions,
    },
    props: {
        data: {
            type: Object as PropType<OverviewData>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const mq = inject("mq");
        const personalInfoStore = usePersonalInfoStore();

        const isReExam = computed(
            () => personalInfoStore.subscription_type === "re-exam"
        );

        return {
            mq,
            atSetConsent: (consent: boolean) => emit("select", consent),
            isReExam,
        };
    },
});
