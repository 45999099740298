import axios from "axios";
import { Zipcode, PollResult, SubscriptionType } from "@/types";
const api: any = {};

const getHeaders = () => {
    return {
        Accept: "application/json",
    };
};

api.bootstrap = (reExamID: string) => {
    return new Promise((resolve, reject) => {
        let url = window.config.baseUrl + "form-data";
        if (reExamID.length > 0) {
            url += "?reExamID=" + reExamID;
        }
        axios({
            method: "get",
            url,
            headers: getHeaders(),
        })
            .then((response) => {
                // console.log(response.data);
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.zipcode = (data: Zipcode) => {
    return new Promise((resolve, reject) => {
        const url = window.config.baseUrl + "zipcode";
        axios({
            method: "post",
            url,
            data,
            headers: getHeaders(),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.register = (data: any) => {
    return new Promise((resolve, reject) => {
        const url = window.config.baseUrl + "register";

        axios({
            method: "post",
            url,
            data,
            headers: getHeaders(),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.getSubscriptionData = (hash: string, discountCode: string | null) => {
    const data: any = {
        hash,
    };
    if (discountCode) {
        data.discount_code = discountCode;
    }
    return new Promise((resolve, reject) => {
        const url = window.config.baseUrl + "subscription-data";
        axios({
            method: "post",
            url,
            headers: getHeaders(),
            data,
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.removeDiscountCode = (hash: string, discountCode: string | null) => {
    return new Promise((resolve, reject) => {
        const url = window.config.baseUrl + "remove-discount-code";
        axios({
            method: "post",
            url,
            headers: getHeaders(),
            data: {
                hash,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.startPoll = (
    subscription_hash: string,
    transaction_hash: string
): Promise<PollResult> => {
    return new Promise((resolve, reject) => {
        const url = window.config.baseUrl + "payments/check";
        const pollInterval = 1000;
        const maxPollAttempts = 10;
        let pollAttempts = 0;

        const poll = () => {
            axios({
                method: "post",
                url,
                data: {
                    subscription_hash,
                    transaction_hash,
                },
                headers: getHeaders(),
            })
                .then((response) => {
                    const status =
                        response.data && response.data.status
                            ? response.data.status
                            : "unknown";

                    const unknownResult: PollResult = {
                        status: "unknown",
                        show_authorization_steps: false,
                        type: "",
                        email: "",
                    };
                    if (status === "unknown") {
                        pollAttempts++;
                        if (pollAttempts >= maxPollAttempts) {
                            resolve(unknownResult);
                        } else {
                            setTimeout(poll, pollInterval);
                        }
                    } else {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        };

        poll();
    });
};

export { api };
