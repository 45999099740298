import { Route } from "@/types";
import Checkout from "@/components/pages/checkout/Checkout.vue";

export const offlineCheckoutRoute: Route = {
    // Dagcursus
    path: "/bevestiging",
    name: "CheckoutOffline",
    component: Checkout,
    meta: {
        dataLayer: {
            event: "checkout",
        },
    },
};

export const onlineCheckoutRoute: Route = {
    path: "/checkout-online",
    name: "CheckoutOnline",
    component: Checkout,
    meta: {
        dataLayer: {
            event: "checkout",
        },
    },
};

export const reExamCheckoutRoute: Route = {
    path: "/checkout-herexamen",
    name: "CheckoutReExam",
    component: Checkout,
    meta: {
        dataLayer: {
            event: "checkout",
        },
    },
};

export const upsellCheckoutRoute: Route = {
    path: "/checkout-aankoop",
    name: "CheckoutUpsell",
    component: Checkout,
    meta: {
        dataLayer: {
            event: "checkout",
        },
    },
};

export const UpgradeCheckoutRoute: Route = {
    path: "/checkout-upgrade",
    name: "CheckoutUpgrade",
    component: Checkout,
    meta: {
        dataLayer: {
            event: "checkout",
        },
    },
};

export const ExtendCheckoutRoute: Route = {
    path: "/checkout-extend",
    name: "CheckoutExtend",
    component: Checkout,
    meta: {
        dataLayer: {
            event: "checkout",
        },
    },
};

export const checkoutRoutes: Route[] = [
    offlineCheckoutRoute,
    onlineCheckoutRoute,
    reExamCheckoutRoute,
    upsellCheckoutRoute,
    UpgradeCheckoutRoute,
    ExtendCheckoutRoute,
];
