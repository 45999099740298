
import { computed, defineComponent, inject, PropType } from "vue";
import { PersonalInfo } from "@/types";
import { formatDate } from "../../../../../utils/date";
import { useUiStore } from "@/store/ui";
import useSlides from "@/composables/useSlides";
import { SLIDE } from "@/data/constants";

export default defineComponent({
    name: "OverviewPersonalInfo",
    props: {
        personalInfo: {
            type: Object as PropType<PersonalInfo>,
            required: true,
        },
    },
    setup(props) {
        const mq = inject("mq");
        const uiStore = useUiStore();
        const { slideNames } = useSlides();

        const jumpTo = () => {
            const slideIndex = slideNames.value.indexOf(SLIDE.PERSONAL_INFO);
            if (slideIndex > -1) {
                uiStore.jumpToSlide(slideIndex);
            }
        };

        const formattedDob = computed(() => formatDate(props.personalInfo.dob));

        return {
            mq,
            jumpTo,
            formattedDob,
        };
    },
});
