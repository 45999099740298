import Planner from "@/components/pages/planner/Planner.vue";
import PaymentCheck from "@/components/pages/checkout/payment/PaymentCheck.vue";
import Info from "@/components/pages/info/Info.vue";

export const plannerRoutes = {
    path: "/",
    name: "Planner",
    component: Planner,
    meta: {
        dataLayer: {
            event: "Planner",
        },
    },
};

export const paymentCheckRoute = {
    path: "/betaling-controleren",
    name: "PaymentCheck",
    component: PaymentCheck,
};

export const infoRoute = {
    path: "/info",
    name: "Info",
    component: Info,
};
