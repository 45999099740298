
import { computed, defineComponent, inject, PropType } from "vue";
import { OptionGroup, Option as OptionType } from "@/types";
import OptionGroupOption from "./Option.vue";
import SlideBody from "../slider/SlideBody.vue";

export default defineComponent({
    name: "OptionGroup",
    components: { SlideBody, OptionGroupOption },
    props: {
        title: {
            type: String,
            required: true,
        },
        optionGroup: {
            type: Object as PropType<OptionGroup>,
            required: true,
        },
        selection: {
            type: Object as PropType<OptionType>,
        },
    },
    setup(props, { emit }) {
        const mq = inject("mq");

        const cols = computed(() => {
            const n = props.optionGroup.options.length;
            if (n > 3) {
                return 2;
            } else {
                return n;
            }
        });

        const select = (option: OptionType) => emit("select-option", option);

        return {
            select,
            mq,
            cols,
        };
    },
});
