import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_card = _resolveComponent("etd-card")!
  const _component_etd_container = _resolveComponent("etd-container")!
  const _component_etd_page = _resolveComponent("etd-page")!

  return (_openBlock(), _createBlock(_component_etd_page, {
    "main-full-width": "",
    "main-full-height": "",
    "hide-intro": "",
    "enable-logo": ""
  }, {
    main: _withCtx(() => [
      _createVNode(_component_etd_container, { gap: "xl" }, {
        default: _withCtx(() => [
          _createVNode(_component_etd_card, null, {
            default: _withCtx(() => [
              _createTextVNode(" Versie: " + _toDisplayString(_ctx.version), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}