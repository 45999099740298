
import { defineComponent } from "vue";
import { useUiStore } from "@/store/ui";

export default defineComponent({
    name: "HelpdeskButton",
    setup() {
        const uiStore = useUiStore();

        return {
            open: () => (uiStore.displayHelpdeskPopup = true),
        };
    },
});
