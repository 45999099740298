import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_checkbox_deluxe = _resolveComponent("etd-checkbox-deluxe")!
  const _component_etd_container = _resolveComponent("etd-container")!
  const _component_slide_body = _resolveComponent("slide-body")!
  const _component_error_popup = _resolveComponent("error-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_etd_container, { gap: "l" }, {
      default: _withCtx(() => [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t(`slides.SlideDates.details.methods.${_ctx.dateMethodKey}.head`)), 1),
        _createElementVNode("div", {
          innerHTML: 
                _ctx.$t(`slides.SlideDates.details.methods.${_ctx.dateMethodKey}.body`, {
                    maxOptions: _ctx.maxOptions,
                })
            
        }, null, 8, _hoisted_1),
        _createVNode(_component_slide_body, null, {
          default: _withCtx(() => [
            _createVNode(_component_etd_container, {
              direction: "row",
              gap: "xl xxxl",
              wrap: true,
              n: _ctx.mq.t === 's' ? 2 : 4
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
                  return (_openBlock(), _createBlock(_component_etd_checkbox_deluxe, {
                    onClick: ($event: any) => (_ctx.tryOption(option)),
                    key: index,
                    "is-active": _ctx.selection.includes(option),
                    label: option.name,
                    value: option.id
                  }, null, 8, ["onClick", "is-active", "label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["n"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.displayPopup)
      ? (_openBlock(), _createBlock(_component_error_popup, {
          key: 0,
          onClose: _ctx.closePopup
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(`slides.SlideDates.details.methods.${_ctx.dateMethodKey}.warning`, {
                maxOptions: _ctx.maxOptions,
            })), 1)
          ]),
          _: 1
        }, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}