export const scrollToTop = (querySelector?: string) => {
    if (!querySelector) {
        window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
        const element = document.querySelector(querySelector);
        if (element) {
            element.scrollTo({ top: 0, behavior: "smooth" });
        }
    }
};
