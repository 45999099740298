
import { defineComponent } from "vue";
import { etdConsts } from "@innouveau/123-etd/dist/consts/consts";

export default defineComponent({
    name: "HelpdeskContent",
    setup() {
        return {
            etdConsts,
        };
    },
});
