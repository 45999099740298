import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { "data-locator": "slide-head" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_form = _resolveComponent("etd-form")!
  const _component_slide_body = _resolveComponent("slide-body")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mq.t === 's' ? 'div' : 'etd-card'), {
    padding: "xxxxl",
    "border-radius": "xl",
    style: {"margin-bottom":"150px"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.entry + "head")), 1),
      _createElementVNode("div", {
        innerHTML: _ctx.$t(_ctx.entry + 'body')
      }, null, 8, _hoisted_2),
      _createVNode(_component_slide_body, null, {
        default: _withCtx(() => [
          _createVNode(_component_etd_form, {
            onUpdateStatus: _ctx.atUpdate,
            formValue: _ctx.formValue,
            "onUpdate:formValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formValue) = $event)),
            "form-config": _ctx.formConfig
          }, null, 8, ["onUpdateStatus", "formValue", "form-config"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}