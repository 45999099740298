
import { computed, defineComponent, inject, PropType } from "vue";
import { Option } from "@/types";
import { ICONS_NEW } from "../../../../data/constants";
import { OPTION_SLUGS } from "@/data/constants";

export default defineComponent({
    name: "OptionGroupOption",
    components: {},
    props: {
        option: {
            type: Object as PropType<Option>,
            required: true,
        },
        isActive: {
            type: Boolean,
            required: true,
        },
        showPrice: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, { emit }) {
        const mq = inject("mq");
        const iconId = computed(() => {
            if (props.option.slug in ICONS_NEW) {
                return ICONS_NEW[props.option.slug];
            } else {
                return null;
            }
        });

        const select = () => emit("select", props.option);

        const labelLength = computed(
            () => props.option.labels.map((l) => l.value).join("").length
        );

        const showComingSoon = computed(() => {
            return (
                props.option.disabled &&
                props.option.slug === OPTION_SLUGS.ONLINE
            );
        });

        return {
            iconId,
            mq,
            select,
            labelLength,
            showComingSoon,
        };
    },
});
