
import { defineComponent, onMounted, provide } from "vue";
import useMediaQuery from "@innouveau/123-etd/dist/composables/useMediaQuery.min.mjs";
import HelpdeskPopup from "@/components/elements/HelpdeskPopup.vue";
import { useUiStore } from "./store/ui";

export default defineComponent({
    name: "App",
    components: { HelpdeskPopup },
    setup() {
        const { mq } = useMediaQuery();
        const uiStore = useUiStore();

        const setVw = () => {
            const vw = document.documentElement.clientWidth / 100;
            document.documentElement.style.setProperty("--vw", `${vw}px`);
        };

        onMounted(() => setVw());

        window.addEventListener("resize", setVw);

        provide("mq", mq);

        return {
            mq,
            uiStore,
        };
    },
});
