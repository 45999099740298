
import DateMethod from "./DateMethod.vue";
import { defineComponent, inject } from "vue";
import { DateMethod as DateMethodType } from "@/types";

export default defineComponent({
    name: "DateMethods",
    components: { DateMethod },
    props: {
        dateMethods: {
            type: Array,
            required: true,
        },
        selection: {
            type: Object,
            required: false,
        },
    },
    setup(props, { emit }) {
        const mq = inject("mq");

        return {
            select: (dateMethod: DateMethodType) => emit("select", dateMethod),
            mq,
        };
    },
});
