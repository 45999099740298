import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { id: "form-item-consent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_navigation_button = _resolveComponent("etd-navigation-button")!
  const _component_etd_container = _resolveComponent("etd-container")!
  const _component_etd_checkbox = _resolveComponent("etd-checkbox")!

  return (_openBlock(), _createBlock(_component_etd_container, { gap: "xxl" }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("slides.SlideOverview.conditions.head")), 1),
        _createElementVNode("div", {
          innerHTML: _ctx.$t('slides.SlideOverview.conditions.body')
        }, null, 8, _hoisted_1)
      ]),
      _createVNode(_component_etd_container, { gap: "xxl" }, {
        default: _withCtx(() => [
          _createVNode(_component_etd_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_etd_navigation_button, {
                tabindex: "-1",
                label: _ctx.$t('slides.SlideOverview.conditions.termsLinkText'),
                direction: "right",
                href: "https://www.123-theorie.nl/algemene-voorwaarden/"
              }, null, 8, ["label"]),
              _createVNode(_component_etd_navigation_button, {
                tabindex: "-1",
                label: 
                        _ctx.$t('slides.SlideOverview.conditions.privacyLinkText')
                    ,
                direction: "right",
                href: "https://www.123-theorie.nl/privacy-verklaring/"
              }, null, 8, ["label"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_etd_checkbox, {
              onClick: _ctx.toggle,
              "is-active": _ctx.consent,
              label: _ctx.$t('slides.SlideOverview.conditions.consentText')
            }, null, 8, ["onClick", "is-active", "label"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}