
import { defineComponent, inject, PropType } from "vue";
import { DateMethod } from "@/types";
import { SLIDE } from "@/data/constants";

export default defineComponent({
    name: "DateMethod",
    props: {
        dateMethod: {
            type: Object as PropType<DateMethod>,
            required: true,
        },
        isActive: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {
        const mq = inject("mq");
        const key = `slides.${[SLIDE.DATES]}.main.methods.${
            props.dateMethod.slug
        }.`;

        return {
            select: () => emit("select", props.dateMethod),
            key,
            mq,
        };
    },
});
