
import { defineComponent, inject } from "vue";
import HelpdeskButton from "@/components/elements/HelpdeskButton.vue";
import { etdConsts } from "@innouveau/123-etd/dist/consts/consts";

export default defineComponent({
    name: "PaymentUnknown",
    components: { HelpdeskButton },
    setup() {
        const mq = inject("mq");
        return {
            mq,
            etdConsts,
        };
    },
});
