import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_method = _resolveComponent("date-method")!
  const _component_etd_container = _resolveComponent("etd-container")!

  return (_openBlock(), _createBlock(_component_etd_container, {
    wrap: true,
    direction: _ctx.mq.t === 's' ? 'column' : 'row',
    gap: _ctx.mq.t === 's' ? 'l' : 'xxxl',
    n: 2
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dateMethods, (dateMethod) => {
        return (_openBlock(), _createBlock(_component_date_method, {
          key: dateMethod.slug,
          onSelect: _ctx.select,
          "is-active": 
                _ctx.selection !== null && _ctx.selection.slug === dateMethod.slug
            ,
          "date-method": dateMethod
        }, null, 8, ["onSelect", "is-active", "date-method"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["direction", "gap"]))
}