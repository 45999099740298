import { dateMethods } from "../data/mock/form-data/date-methods";
import { DateMethod, FormDataDates } from "@/types";

interface BaseDateMethod {
    id: number;
    uuid: string;
}

const coupleIdsToDateMethods = (
    dateMethods: DateMethod[],
    baseDateMethods: BaseDateMethod[]
) => {
    for (const baseDateMethod of baseDateMethods) {
        const dateMethod = dateMethods.find(
            (d) => d.uuid === baseDateMethod.uuid
        );
        if (dateMethod) {
            dateMethod.id = baseDateMethod.id;
        }
    }
    return dateMethods;
};

export const transformPeriods = (periods: any): FormDataDates => {
    const newObject: any = {};
    newObject.months = periods.month;
    newObject.vacations = periods.vacation;
    newObject.methods = coupleIdsToDateMethods(dateMethods, periods.random);
    return newObject;
};
