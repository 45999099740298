import { BaseProduct, OptionsStateSelection, Slug } from "@/types";
import { OPTION_SLUGS, SLUGS } from "@/data/constants";

export const getOptionGroupById = (baseProducts: BaseProduct[], id: number) => {
    for (const baseProduct of baseProducts) {
        for (const optionGroup of baseProduct.option_groups) {
            if (optionGroup.id === id) {
                return optionGroup;
            }
        }
    }
};

export const getOptionGroupBySlug = (
    baseProducts: BaseProduct[],
    optionGroupSlug: Slug,
    selection: OptionsStateSelection
) => {
    const hasOnline = (selection: OptionsStateSelection) => {
        if (selection[SLUGS.PRODUCT_CATEGORIES]) {
            const slug = selection[SLUGS.PRODUCT_CATEGORIES]
                .slug as keyof typeof window.config.hasOnline;

            return window.config.hasOnline[slug];
        } else {
            return false;
        }
    };
    for (const baseProduct of baseProducts) {
        for (const optionGroup of baseProduct.option_groups) {
            if (optionGroup.slug === optionGroupSlug) {
                if (
                    optionGroupSlug === SLUGS.COURSE_TYPES &&
                    !hasOnline(selection)
                ) {
                    optionGroup.options.forEach((option) => {
                        option.disabled = option.slug === OPTION_SLUGS.ONLINE;
                        return option;
                    });
                    return optionGroup;
                } else {
                    optionGroup.options.forEach((option) => {
                        option.disabled = false;
                        return option;
                    });
                    return optionGroup;
                }
            }
        }
    }
};

export const getOptionBySlug = (
    baseProducts: BaseProduct[],
    optionSlug: Slug
) => {
    for (const baseProduct of baseProducts) {
        for (const optionGroup of baseProduct.option_groups) {
            for (const option of optionGroup.options) {
                if (option.slug === optionSlug) {
                    return option;
                }
            }
        }
    }
};
