import { ConversionRouteNames, GoogleType, GoogleTag } from "@/types";
import { GOOGLE_ADWORDS_CONVERSION_ID } from "@/data/constants";
import { conversionLabels as labels } from "@/data/google-data";

export const getConversionLabel = (routeName: ConversionRouteNames) => {
    const label = labels[routeName];
    return GOOGLE_ADWORDS_CONVERSION_ID + "/" + label;
};

export const getConversionId = () => {
    return GOOGLE_ADWORDS_CONVERSION_ID;
};

export const sendGoogleTag = (
    tag: GoogleTag,
    type: GoogleType,
    payload: unknown
) => {
    const gtag = window.gtag;
    if (gtag) {
        gtag(tag, type, payload);
    } else {
        console.error("gtag was not injected");
    }
};
