
import { defineComponent } from "vue";
import HelpdeskContent from "@/components/elements/HelpdeskContent.vue";

export default defineComponent({
    name: "ErrorPopup",
    components: { HelpdeskContent },
    props: {
        enableHelpdesk: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, { emit }) {
        return {
            close: () => emit("close"),
        };
    },
});
