import {
    validators,
    reformatters,
} from "@innouveau/123-etd/dist/tools/validators";
import { FormConfig } from "@/types/etd";

const formConfig: FormConfig = {
    connectors: [
        {
            type: "zipcode",
            input: {
                zipcode: "zipcode",
                number: "number",
            },
            onSucces: (
                response: any,
                localValue: any,
                items: any[],
                validateItem: (item: any) => boolean
            ) => {
                const keys = ["street", "city"];
                for (const key of keys) {
                    const item = items.find((i) => i.key === key);
                    localValue[key] = response[key];
                    if (item) {
                        item.touched = true;
                        item.blurred = true;
                        item.editable = false;
                        item.valid = validateItem(item);
                    }
                }
                const zipcodeItem = items.find((i) => i.key === "zipcode");
                if (zipcodeItem) {
                    zipcodeItem.valid = validateItem(zipcodeItem);
                }
            },
            onFail: (localValue: any, items: any[]) => {
                const keys = ["street", "city"];
                for (const key of keys) {
                    const item = items.find((i) => i.key === key);
                    localValue[key] = "";
                    if (item) {
                        item.editable = true;
                        item.touched = false;
                        item.blurred = false;
                    }
                }
                const zipcodeItem = items.find((i) => i.key === "zipcode");
                if (zipcodeItem) {
                    zipcodeItem.valid = false;
                    zipcodeItem.hint =
                        "Helaas konden we jouw combinatie van postcode en huisnummer niet vinden. Klopt deze wél? Vul dan je straatnaam en woonplaats in.";
                }
            },
        },
    ],
    settings: {
        displayRequiredMark: true,
        displayPositiveFeedback: true,
        validateAt: "blur",
        scrollIntoView: true,
        scrollIntoViewElement: "main",
    },
    cards: [
        {
            type: "transparent",
            sections: [
                {
                    title: "Persoonsgegevens",
                    description:
                        "<p>Graag je voor- en achternaam exact zoals in je paspoort vermeld invullen.</p>",
                    type: "regular",
                    rows: [
                        {
                            items: [
                                {
                                    key: "firstname",
                                    label: "Voornaam",
                                    type: "text",
                                    editable: true,
                                    required: true,
                                    validation: [
                                        {
                                            hint: "Dit veld mag niet leeg zijn",
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            items: [
                                {
                                    key: "insertion",
                                    label: "Tussenvoegsel",
                                    required: false,
                                    type: "text",
                                    editable: true,
                                    validation: [
                                        {
                                            hint: "Dit veld mag niet leeg zijn",
                                        },
                                    ],
                                },
                                {
                                    key: "lastname",
                                    label: "Achternaam",
                                    required: true,
                                    type: "text",
                                    editable: true,
                                    validation: [
                                        {
                                            hint: "Dit veld mag niet leeg zijn",
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            items: [
                                {
                                    key: "dob",
                                    label: "Geboortedatum",
                                    required: true,
                                    type: "dob",
                                    editable: true,
                                    validation: [
                                        {
                                            hint: "Dit is geen geldige datum.",
                                            validator: validators.date,
                                        },
                                        {
                                            hint: "Je moet ouder dan 14 zijn.",
                                            validator: (value: string) => {
                                                return validators.minAge(
                                                    value,
                                                    14
                                                );
                                            },
                                        },
                                        {
                                            hint: "Je mag maximaal 100 jaar oud zijn.",
                                            validator: (value: string) => {
                                                return validators.maxAge(
                                                    value,
                                                    100
                                                );
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            items: [
                                {
                                    key: "email",
                                    label: "E-mailadres",
                                    required: true,
                                    type: "email",
                                    editable: true,
                                    validation: [
                                        {
                                            validator: validators.email,
                                            hint: "Dit is geen geldig e-mailadres",
                                        },
                                        {
                                            validator: validators.emailTypo,
                                            hint: ".con en .co zijn geen geldige e-mailadressen",
                                        },
                                    ],
                                    reformatter: reformatters.removeSpaces,
                                },
                            ],
                        },
                        {
                            items: [
                                {
                                    key: "cell",
                                    label: "Telefoonnummer",
                                    required: true,
                                    type: "tel",
                                    editable: true,
                                    validation: [
                                        {
                                            validator: validators.telStart,
                                            hint: "Je telefoonnummer moet met een 0 beginnen",
                                        },
                                        {
                                            validator: validators.telLength,
                                            hint: "Een telefoonnummer moet uit 10 cijfers bestaan",
                                        },
                                    ],
                                    reformatter:
                                        reformatters.removeAllNonNumeric,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: "transparent",
            sections: [
                {
                    title: "Adresgegevens",
                    type: "regular",
                    rows: [
                        {
                            items: [
                                {
                                    key: "zipcode",
                                    label: "Postcode",
                                    required: true,
                                    type: "zipcodeNl",
                                    editable: true,
                                    validation: [
                                        {
                                            hint: "Dit is geen geldige postcode",
                                        },
                                    ],
                                },
                                {
                                    key: "number",
                                    label: "Huisnummer",
                                    required: true,
                                    type: "text",
                                    editable: true,
                                    validation: [
                                        {
                                            hint: "Dit veld mag niet leeg zijn",
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            items: [
                                {
                                    key: "street",
                                    label: "Straatnaam",
                                    required: true,
                                    type: "text",
                                    editable: true,
                                    validation: [
                                        {
                                            hint: "Dit veld mag niet leeg zijn",
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            items: [
                                {
                                    key: "city",
                                    label: "Plaats",
                                    required: true,
                                    type: "text",
                                    editable: true,
                                    validation: [
                                        {
                                            hint: "Dit veld mag niet leeg zijn",
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

export default formConfig;
