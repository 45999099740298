
import { defineComponent, inject, PropType } from "vue";
import OverviewSummaryItem from "./OverviewSummaryItem.vue";
import { SummaryItem } from "@/types";
import useSlides from "../../../../../composables/useSlides";
import { useUiStore } from "@/store/ui";
import { useOptionsStore } from "@/store/options";

export default defineComponent({
    name: "OverviewSummary",
    components: { OverviewSummaryItem },
    props: {
        summaryItems: {
            type: Array as PropType<SummaryItem[]>,
            required: true,
        },
    },
    setup() {
        const mq = inject("mq");
        const uiStore = useUiStore();
        const optionsStore = useOptionsStore();

        const { slideNames } = useSlides();

        const jumpTo = (summaryItem: SummaryItem) => {
            let slideIndex;
            slideIndex = slideNames.value.indexOf(summaryItem.component);
            // the slide was probably hidden by preselection
            // but we want to navigate to it now anyway
            // so lets clear the preselection
            if (slideIndex === -1) {
                optionsStore.clearPreselection();
                slideIndex = slideNames.value.indexOf(summaryItem.component);
            }
            if (slideIndex > -1) {
                uiStore.jumpToSlide(slideIndex);
            }
        };

        return {
            mq,
            jumpTo,
        };
    },
});
